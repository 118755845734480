import {Menu, MenuButton, MenuItems, MenuItem, MenuSeparator} from '@headlessui/react';
import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {ComponentProps, FC, MouseEventHandler} from 'react';
import LivanLink from '@/components/core/LivanLink';
import {useSignals} from '@preact/signals-react/runtime';
import type {HeroiconComponent} from '@/utils/icon';

export type DropdownMenuItem = XOR<
  {
    label: string;
    Icon: HeroiconComponent | null;
    // styleType?: StyleType;
  } & XOR<
    {
      to: string;
      onClick: MouseEventHandler<HTMLAnchorElement>;
    },
    {
      to: string;
    },
    {
      onClick: MouseEventHandler<HTMLAnchorElement>;
    }
  >,
  {
    isDivider: true;
  }
>;

interface Props extends BaseComponentProps {
  items: DropdownMenuItem[];
  anchor?: ComponentProps<typeof MenuItems>['anchor'];
  truncate?: boolean;
  buttonRole?: ComponentProps<typeof MenuButton>['role'];
  fullWidth?: boolean;
}

const DropdownMenu: FC<Props> = function DropdownMenu(props) {
  useSignals();
  const {className, items, children, anchor, truncate, buttonRole, fullWidth} = props;

  return (
    <Menu
      as="div"
      className={clsx(className, 'DropdownMenu relative')}
    >
      <MenuButton
        role={buttonRole}
        className={clsx(truncate && 'truncate', fullWidth && 'w-full', 'flex items-center')}
        aria-haspopup="menu"
        aria-controls="dropdown-menu"
      >
        {children}
      </MenuButton>
      <MenuItems
        id="dropdown-menu"
        transition
        className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        anchor={anchor}
      >
        {items.map((item, i) => {
          if (item.isDivider) {
            return (
              <MenuSeparator
                key={`divider-${i}`}
                className="my-1 h-px w-full bg-gray-200"
              />
            );
          }
          const {label, onClick, to} = item;
          return (
            <MenuItem key={label}>
              <LivanLink
                role="menuitem"
                to={to}
                type="unstyled"
                className="px-4 py-2 data-[focus]:bg-gray-100 flex items-center gap-3 text-livan-black"
                onClick={onClick}
                prefetch="viewport"
              >
                {item.Icon && <item.Icon className="size-4 shrink-0" />}
                {item.label}
              </LivanLink>
            </MenuItem>
          );
        })}
      </MenuItems>
    </Menu>
  );
};

export default DropdownMenu;
